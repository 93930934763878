* { -webkit-hyphens: none; }
div {
	/* These are technically the same, but use both */
	overflow-wrap: auto;
	word-wrap: auto;

	-ms-word-break: auto;
	/* This is the dangerous one in WebKit, as it breaks things wherever */
	word-break: auto;
	/* Instead use this non-standard one: */
	word-break: auto;

	/* Adds a hyphen where the word breaks, if supported (No Blink) */
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}
.clear {clear: both;}
body {
	background-color: #ffffff;
	margin: 0px;
	font-family: 'Fira Sans', sans-serif;
	font-size: 16px;
	color: #000000;
	font-weight: 300;
	position: relative;
}
#current-listings-frame {
	width: 100%;
	margin: 0 auto;
	@media screen and (min-width: $deskwidth) {
		// width: 980px;
	}
}

.class {

	@media screen and (min-width: $tabwidth) {

	}

	@media screen and (min-width: $deskwidth) {
	
	}
	
}

#page-right-column .infusion-field-input-container {
	height: 21px;
	-moz-border-radius: 0px;
	border-radius: 0px;
	width: 70%;
}
.infusion-field-input-container {
	height: 30px;
	-moz-border-radius: 0px;
	border-radius: 0px;
	width: 85%;
}
.infusion-field textarea {
	-moz-border-radius: 0px;
	border-radius:0px;
	width: 100%;
}
#advanced-search-link {
	float: right;
	width: 100%;
	text-align: right;
	margin-top: 15px;
}
#page-right-column #advanced-search-link {
	float: right;
	width: 100%;
	text-align: left;
	margin-top: 15px;
}
.container {
	clear: both;
}
.idx-search-pusher {
	margin-bottom: 45px;
}
#idx {
	width: 100%;
	float: left;
}

#idxmap_canvas {
	height: 380px!important;
    margin-top: 18px!important;
}

.idxprop {
	width: 100%;
	float: left;
	
	margin-bottom: 15px!important;
	// height: 400px!important;
	font-size: 14px!important;

	@media screen and (min-width: $tabwidth) {
		width: 30%;
		margin-right: 3%!important;
	}

	@media screen and (min-width: $deskwidth) {
	
	}
}

.idxprop a {
	background-color: #0099CF;
	color: #ffffff;
	font-weight: normal!important;
	padding: 5px!important;
	    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
	margin-bottom: 10px;
	margin-top: 10px;
	display: inline-block;
}

.idxprop a:link { color: #ffffff; text-decoration: none; }
.idxprop a:visited { color: #ffffff; text-decoration: none; }
.idxprop a:hover { color: #ffffff; text-decoration: none; }

.idxphoto2  a {
	background-color: transparent!important;
	color: #ffffff;
}


.idxclear {
	clear: none!important;
}

.idxphoto2 {
    background: #ececec;
    padding: 0px;
    margin-top: 0;
    margin-right: 0px!important;
    margin-bottom: 10px!important;
    margin-left: 0;
    width: 100%!important;
    height: auto!important;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    float: left;
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
}

.idxphoto2 img {
	width: 100%!important;
	max-width: 100%!important;
	height: auto!important;
}



input {
	-moz-border-radius: 5px;
	border-radius: 5px;
	color: #000000;
}
.infusion-field p {
	margin-bottom: 0px;
	margin-top: 5px;
}
.nivo-lightbox-content {
	width: 50%!important;
}
.nivo-lightbox-wrap {
	position: absolute;
	top: 20%!important;
	bottom: 10%;
	left: 35%!important;
	right: 0%!important;
}
#chat-bar {
	position: fixed;
	bottom: 0px;
	right: 0px;
	width: 100%;
	height: 64px;
	z-index: 9999999;
}
#chat-bar-background-wrapper {
	width: 100%;
	height: 64px;
	float: left;
	position: relative;
}
#chat-bar-background {
	width: 100%;
	height: 27px;
	position: absolute;
	bottom: 0px;
	left: 0px;
	background-color: #334EA5;
}
#chat-tab {
	width: 151px;
	height: 36px;
	z-index: 9999999;
	position: absolute;
	top: -36px;
	right: 10%;
}
// .wrapper {
// 	width: 100%;
// 	margin: 0px auto;
// 	padding: 20px;
	
// 	@media screen and (min-width: $tabwidth) {
// 		width: 100%;
// 	}
// 	@media screen and (min-width: $deskwidth) {
// 		width: 1200px;
// 		padding: 0px;
// 	}
// }

// .content-area {
// 	width: 100%;
// 	margin: 0px auto;
	
// 	@media screen and (min-width: $tabwidth) {
// 		width: 66%;
// 		float: left;
// 		margin: 0;
// 	}
// 	@media screen and (min-width: $deskwidth) {
// 		/*width: 1200px;*/
// 	}
// }

// .widget-area {
// 	width: 100%;
// 	margin: 0px auto;
	
// 	@media screen and (min-width: $tabwidth) {
// 		width: 26%;
// 		float: right;
// 		margin: 0;
// 	}
// 	@media screen and (min-width: $deskwidth) {
// 		/*width: 1200px;*/
// 	}
// }

/* Logo */
// .logo , h1.logo {
// 	width: 300px;
// 	height: 200px;
// 	background-image: url(images/logo.png);	
// 	margin: 10px auto;

// 	@media screen and (min-width: $tabwidth) {
// 		width: 300px;
// 		height: 200px;
// 		float: left;
// 		margin: 0;
// 	}
// 	@media screen and (min-width: $deskwidth) {
// 		width: 1200px;
// 	}
// }
// .logo a, h1.logo a {
// 	width: 300px;
// 	height: 200px;
// 	display: block;
// 	text-indent: -9000px;

// 	@media screen and (min-width: $tabwidth) {
// 		width: 300px;
// 		height: 200px;
// 	}
// 	@media screen and (min-width: $deskwidth) {
// 		/*width: 1200px;*/
// 	}
// }
.dfres-subdivision-detail {
	margin-bottom: 20px!important;
}

.idxdisclosure {
	font-size: 12px!important;
	margin-top: 15px!important;
}

.idximgnoborder {
	margin-top: 15px!important;
}

.idximgnoborder img {
	width: 25px!important;
	height: auto!important;
}


.wp-font {
	font-family: inherit!important;
	font-size:  inherit!important;
}

.btn-default {
	background-color: #357ebd!important;
    border-color: #357ebd!important;
	color: #ffffff!important;
	text-decoration: none!important;
}

.btn-default a:link { color: #ffffff!important; text-decoration: none!important; background-color: #428bca!important;}
.btn-default a:visited { color: #ffffff!important; text-decoration: none!important; }
.btn-default a:hover { color: #ffffff!important; text-decoration: none!important; background-color: #334EA5!important;}

.dfres-price-detail {
	color: #0099CF!important;
	    padding-bottom: 9px!important;
}

.dfres-subhead {
	color: #0099CF!important;
}

.acf-map {
	width: 100%;
	height: 330px;
	border: #ccc solid 1px;
	margin: 0px 0;
}
#main-wrapper-all {
	width: 100%;
	float: left;
	height: 100%;
	position: relative;
	overflow: hidden;
}
#header-wrapper {
	width: 100%;
	// height: 178px;
	float: left;
	background-color: #000000;
}
#main-header {
	width: 100%;
	
	// height: 178px;
	margin-left: auto;
	margin-right: auto;
	@media screen and (min-width: $navbreakwidth) {
		padding: 5%;
	}

	@media screen and (min-width: $deskwidth) {
		width: 85%;
		padding: 0;
	}
}
#main2 {
	width: 100%;
	margin: 0 auto;
	position: relative;
	@media screen and (min-width: $tabwidth) {

	}

	@media screen and (min-width: $deskwidth) {
		// width: 1120px;
		width: 90%;
	}
}
#bottom-link {
	width: auto;
	margin:  30px 0;
	float: left;
	a {
		width: auto;
	    background-color: rgba(55,83,161, 1);
	    border-radius: 6px;
	    line-height: 4px !important;
	    padding: 20px 15px;
	    display: inline-block;
	    color: #fff;
	    text-decoration: none;

		&:hover {
			background-color: rgba(55,83,161, .7);
		}

	}
}
#main-wrapper {
	width: 100%;
	float: left;
	background-image: url(images/charlotte-center-city.jpg);
	background-repeat: no-repeat;
	background-size: cover;
}
#main {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	@media screen and (min-width: $tabwidth) {

	}

	@media screen and (min-width: $deskwidth) {
		// width: 980px;
		width: 90%;
	}
}

.bw-search #main {
	width: 100%;
	@media screen and (min-width: $tabwidth) {

	}

	@media screen and (min-width: $deskwidth) {
		width:1120px;
	}
}

/* JH */

.dfres-address-detail {
	    line-height: 22px;
		    padding-bottom: 9px;
}

#map-top-right {
    width: 100%;
    // height: 102px;
    float: left;
    padding-top: 20px;
    @media screen and (min-width: $tabwidth) {
    	width: 50%;
    	float: right;
	}

	@media screen and (min-width: $deskwidth) {
		// width: 830px;
	}
}

.bw-search #map-top-left {
    width: 223px;
    // height: 102px;
    float: left;
   padding-top: 20px;

     @media screen and (min-width: $tabwidth) {
     	width: 40%;
     	float: left;
     	border-right: 1px solid #131313;
     }
}

.bw-search #search-fields {
    width: 290px;
    height: 695px;
    float: left;
    padding-top: 15px;
    color: #373737;
}

.bw-search #google-search-map {
    width: 100%;
    height: 475px;
    float: left;
    margin-right: 45px;
    @media screen and (min-width: $tabwidth) {

	}

	@media screen and (min-width: $deskwidth) {
		width: 655px;
	}
}
#header {
	width: 100%;
	float: left;
}
#logo {
	width: 280px;
	// height: 91px;
	margin: 0 auto;
	padding-top: 12px;
	@media screen and (min-width: $tabwidth) {
		float: left;
		margin: 0;
	}

	@media screen and (min-width: $deskwidth) {
		width: 362px;

	}

	a {
		display: block;
		width: 280px;
		@media screen and (min-width: $deskwidth) {
			width: 362px;
		}
	}
}
#header-content-wrapper {
	width: auto;
	// height: 60px;
	
	padding-top: 10px;
	@media screen and (min-width: $tabwidth) {
		float: right;
	}
	@media screen and (min-width: $deskwidth) {
		width: 550px;
	}
}
#header-content {
	width: 100%;
	// height: 60px;
	float: right;
	position: relative;
}
#header-content1 {
	width: 100%;
	// height: 30px;
	float: left;
	font-size: 13px;
	color: #A4A3A4;
	text-align: center;
	
	@media screen and (min-width: $tabwidth) {
		width: auto;
		padding-top: 10px;
		padding-right: 25px;
		text-align: right;
	}

	&:after {
		clear: both;
		width: 100%;
		content: '';
		@media screen and (min-width: $deskwidth) {
			clear: none;
		}
	}
}
.header-cont-right {
	width: 100%;
	clear: both;
	@media screen and (min-width: $deskwidth) {
		width: auto;
		clear: none;
	}
}
#header-content2 {
	width: 100%;
	// height: 40px;
	float: left;
	font-size: 26px;
	color: #FFFFFF;
	text-align: center;
	@media screen and (min-width: $tabwidth) {
		text-align: right;
		width: auto;
		float: right;
	}
	a {
		color: #fff;
		text-decoration: none;
	}
}
#social-icons {
	width: 114px;
	// height: 40px;
	float: left;
	display: none;

	@media screen and (min-width: $tabwidth) {
		display: block;
	}
}
#social1 {
	width: 40px;
	height: 40px;
	float: left;
	margin-right: 10px;
}
#social1 a {
	width: 40px;
	height: 40px;
	background-image: url(images/social1.png);
	background-position: 0 0;
	display: block;
}
#social1 a:hover {
	width: 40px;
	height: 40px;
	background-image: url(images/social1.png);
	background-position: 0 -40px;
	display: block;
}
#social2 {
	width: 40px;
	height: 40px;
	float: left;
	margin-right: 2px;
}
#social2 a {
	width: 40px;
	height: 40px;
	background-image: url(images/social2.png);
	background-position: 0 0;
	display: block;
}
#social2 a:hover {
	width: 40px;
	height: 40px;
	background-image: url(images/social2.png);
	background-position: 0 -40px;
	display: block;
}
#social3 {
	width: 43px;
	height: 42px;
	float: right;
	margin-left: 10px;
}
#social3 a {
	width: 43px;
	height: 42px;
	background-image: url(images/social3.png);
	background-position: 0 0;
	display: block;
}
#social3 a:hover {
	width: 43px;
	height: 42px;
	background-image: url(images/social3.png);
	background-position: 0 -42px;
	display: block;
}
#social4 {
	width: 43px;
	height: 42px;
	float: right;
	margin-left: 10px;
}
#social4 a {
	width: 43px;
	height: 42px;
	background-image: url(images/social4.png);
	background-position: 0 0;
	display: block;
}
#social4 a:hover {
	width: 43px;
	height: 42px;
	background-image: url(images/social4.png);
	background-position: 0 -42px;
	display: block;
}
#facebook-box {
	width: 100%;
	float: left;
	margin: 5px 0px 10px 0px;
}
.facebook1 {
	width: 40px;
	height: 40px;
	float: left;
	margin-right: 10px;
}
.facebook1 a {
	width: 40px;
	height: 40px;
	background-image: url(images/social1.png);
	background-position: 0 0;
	display: block;
}
.facebook1 a:hover {
	width: 40px;
	height: 40px;
	background-image: url(images/social1.png);
	background-position: 0 -40px;
	display: block;
}
.youtube1 {
	width: 40px;
	height: 40px;
	float: left;
	margin-right: 10px;
}
.youtube1 a {
	width: 40px;
	height: 40px;
	background-image: url(images/social2.png);
	background-position: 0 0;
	display: block;
}
.youtube1 a:hover {
	width: 40px;
	height: 40px;
	background-image: url(images/social2.png);
	background-position: 0 -40px;
	display: block;
}
.twitter1 {
	width: 40px;
	height: 40px;
	float: left;
	margin-right: 10px;
}
.twitter1 a {
	width: 40px;
	height: 40px;
	background-image: url(images/social5.png);
	background-position: 0 0;
	display: block;
}
.twitter1 a:hover {
	width: 40px;
	height: 40px;
	background-image: url(images/social5.png);
	background-position: 0 -40px;
	display: block;
}

#home-wrapper {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	@media screen and (min-width: $tabwidth) {

	}

	@media screen and (min-width: $deskwidth) {
		width: 1120px;
	}
}
#home-row1 {
	width: 100%;
	// height: 613px;
	float: left;
	border-bottom: 19px solid #000000;
}
.template-sell {
	#page-text-left {
		@media screen and (min-width: $tabwidth) {
			width: 50%;
		}
		.infusion-submit input {
			background-color: #3753A1;
			font-size: 25px;
		}
		.infusion-field textarea {
			width: 85%;
		}
	}
	#page-right-column {
		@media screen and (min-width: $tabwidth) {
			width: 40%;
		}
		.button {
			cursor: pointer;
			@include flexbox();
			@include flexbox-justify(space-between);
			@include flexbox-align-items(center);
			@include flexbox-align-content(center);
			@include flexbox-direction(row);
			text-align: center;
			padding: 20px;
			background-color: #3753A1;
			margin: 20px 0 0 0;
			color: white;
			border-radius: 6px;
			vertical-align: middle;
			@media screen and (min-width: $tabwidth) {
				width: 100%;
			}
			&:first-child {
				margin-top: 0;
			}
			a {
				color: inherit;
				text-decoration: none;
				@media screen and (min-width: $midwidth) {
					font-size: 25px;
				}
				&:hover {
					color: inherit;
				}
			}
			&:after {
				font-family: "flexslider-icon";
				font-size: 30px;
				color: white;
				display: block;
				content: '\f002';
				margin-left: 10px;
				line-height: 1;
				vertical-align: middle;
			}
		}
	}
}
#find-box-wrapper {
	#sell-box {
		@media screen and (min-width: $midwidth) {
			padding: 8% 15%;
		}
		@media screen and (min-width: $deskwidth) {
			padding: 8% 10%;
		}
		&:after {
			clear: both;
			content: "";
			display: block;
		}
		>h2{
			margin-top: 20px;
			margin-bottom: 0;
			font-size: 25px;
			font-weight: 500;
			color: white;
		}
		>.col-1 {
			@media screen and (min-width: $tabwidth) {
				float: left;
				width: 50%;
			}
			@media screen and (min-width: $midwidth) {
				width: 60%;
			}
			h1,h2,h3,h4 {
				color: white;
			}
			h2 {
				font-size: 25px;
				font-weight: 500;
			}


		}
		>.col-2 {
			@media screen and (min-width: $tabwidth) {
				float: left;
				width: 50%;
				padding: 0 0 0 5%;
			}
			@media screen and (min-width: $midwidth) {
				width: 40%;
			}
			.button {
				cursor: pointer;
				@include flexbox();
				@include flexbox-justify(space-between);
				@include flexbox-align-items(center);
				@include flexbox-align-content(center);
				@include flexbox-direction(row);
				padding: 10px;
				background-color: #3753A1;
				margin: 20px 0 0 0;
				text-align: center;
				text-decoration: none;
				color: white;
				border-radius: 6px;
				vertical-align: middle;
				@media screen and (min-width: $tabwidth) {
					width: 100%;
				}
				&:first-child {
					margin-top: 0;
				}
				a {
					color: inherit;
					text-decoration: none;
					font-size: 14px;
					&:hover {
						color: inherit;
					}
				}
				&:after {
					font-family: "flexslider-icon";
					font-size: 15px;
					color: white;
					display: block;
					content: '\f002';
					margin-left: 10px;
					line-height: 1;
				}
			}
		}
	}
}
#home-slider {
	width: 100%;
	
	float: left;
	position: relative;
	overflow: hidden;
	@media screen and (min-width: $tabwidth) {

	}

	@media screen and (min-width: $deskwidth) {
		// height: 613px;
	}
}
#mortgage-calc {
	padding: 0%;
	width: 100%;
}
.mortgage-calc-pad {
	padding: 10%;
}
.mort-calc-pad {
	width: 100%;
	height: 70px;
	background-color: rgba(0,0,0,.8);
}
.calculator {
	border-style: solid;
	border-color: #ffffff;
	border-width: 0px;
	border-top: 0px;
	border-bottom: 0px;
	border-left: 0px;
	border-right: 0px;
	border: solid;
}
blockquote {
	width: 223px;
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 40px;
	float: right;
	font-size: 19px;
	color: #118bbf;
	font-weight: bold;
	text-align: center;
	line-height: 20px;
	margin-right: 0px;
	margin-top: 0px;
}
.post-container {
	width: 100%;
	float: left;
	@media screen and (min-width: $tabwidth) {

	}

	@media screen and (min-width: $deskwidth) {
		width: 1207px;
		padding-left: 120px;
		min-height: 400px;
	}
}

#row1 {
	width: 100%;
	float: left;
	@media screen and (min-width: $tabwidth) {

	}

	@media screen and (min-width: $deskwidth) {
		width: 1254px;
		padding-left: 146px;
	}
}
#footer {
	width: 100%;
	// height: 325px;
	float: left;
	text-align: center;
	padding: 0 0 120px 0;
	background-color: #000000;
}
#footer a:link {
	color: #ffffff;
	text-decoration: none;
}
#footer a:visited {
	color: #ffffff;
	text-decoration: none;
}
#footer a:hover {
	color: #c0c0c0;
	text-decoration: none;
}
#footer-wrapper {
	width: 100%;
	// height: 305px;
	margin: 0 auto;
	background-image: url(images/bg-footer-new.png);
	background-repeat: no-repeat;
	background-position: bottom center;
	margin-top: 30px;
	overflow: hidden;
	@media screen and (min-width: $tabwidth) {
		background-position: bottom right;
	}

	@media screen and (min-width: $deskwidth) {
		// width: 1130px;
		width: 90%;
	}
}


#footer-newsletter {
	width: 100%;
	float: left;
	padding-top: 20px;

	@media screen and (min-width: $tabwidth) {
		width: 30%;
	}
}
.newsletter-signup {
	width: auto;
	display: inline-block;
	margin: 0 auto;
	a {
		width: auto;
	    background-color: #3855A3;
	    padding: 15px 20px;
	    text-align: center;
	    border-radius: 7px;
	    margin-bottom: 20px;
	    display: inline-block;
	    font-size: 15px;
	    text-decoration: none;
	    color: #fff;
	    @include transition(all 0.3s ease);
	}

	@media screen and (min-width: $tabwidth) {
		// float: left;
		margin: 0;
	}
}
#footer-content2 {
	width: 100%;
	padding: 20px 5%;
	@media screen and (min-width: $tabwidth) {
		width: 30%;
		float: right;
	}
	@media screen and (min-width: $deskwidth) {
		width: 170px;
		padding: 20px 0 0 0;
	}
}
#footer-social {
	width: auto;
	display: inline-block;
	@media screen and (min-width: $tabwidth) {
		width: 100%;
		float: left;
	}
}
#footer-links {
	width: 100%;
	float: left;
	color: #ffffff;
	
	padding-top: 10px;
	@media screen and (min-width: $tabwidth) {
		text-align: right;
	}
	ul {
		margin: 0;
		padding: 0;
	}
	li {
		margin: 10px 0;
		padding: 0;
		list-style: none;
	}
}
#footer2 {
	width: 100%;
	float: left;
	font-size: 14px;
	font-weight: bold;
	color: #666666;
	margin: 40px 0 0 0;
}
#footer2 a:link {
	color: #666666;
	text-decoration: none;
}
#footer2 a:visited {
	color: #666666;
	text-decoration: none;
}
#footer2 a:hover {
	color: #ffffff;
	text-decoration: none;
}
#footer-row1-box1 {
	width: 100%;
	padding: 5%;
	float: left;
	text-align: left;
	color: #ffffff;
	@media screen and (min-width: $tabwidth) {
		width: 40%;

	}

	@media screen and (min-width: $deskwidth) {
		width: 35%;
		padding: 0;
	}
}
.item-address {
	width: 100%;
	float: left;
	margin: 0 0 20px 0;
	text-align: center;
	@media screen and (min-width: $tabwidth) {
		text-align: left;
	}
}
#footer-row1-box1 h2 {
	color: #279DD7;
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 5px;
	
}
#page-heading {
	margin-bottom: 15px;
	float: left;
	width: 100%;
}
#page-text {
	margin-bottom: 15px;
	float: left;
	width: 100%;
}
#page-text-left {
	margin-bottom: 15px;
	float: left;
	width: 100%;
	@media screen and (min-width: $midwidth) {
		width: 60%;
	}

	@media screen and (min-width: $deskwidth) {
		// width: 630px;
	}
	h2 {
		width: 100%!important;
		margin-top: 20px!important;
	}
	p {
		width: 100%!important;
		margin-top: 20px!important;
	}
	.purple-button {
		cursor: pointer;
		@include flexbox();
		@include flexbox-justify(space-between);
		@include flexbox-align-items(center);
		@include flexbox-align-content(center);
		@include flexbox-direction(row);
		text-align: center;
		padding: 20px;
		background-color: #3753A1;
		margin: 20px 0 0 0;
		color: white;
		border-radius: 6px;
		vertical-align: middle;
		@media screen and (min-width: $tabwidth) {
			width: 100%;
			max-width: 300px;
		}
		&:first-child {
			margin-top: 0;
		}
		a {
			color: inherit;
			text-decoration: none;
			@media screen and (min-width: $midwidth) {
				font-size: 18px;
			}
			&:hover {
				color: inherit;
			}
		}
		&:after {
			font-family: "flexslider-icon";
			font-size: 30px;
			color: white;
			display: block;
			content: '\f002';
			margin-left: 10px;
			line-height: 1;
			vertical-align: middle;
		}
	}
}
.text {
	width: 100%!important;
	float: left!important;
	margin-top: 20px!important;
}
.offices-phone {
	padding-top: 8px;
	font-size: 12px;	
}

.sidebar-directions {
	font-size: 12px;
	padding-top: 6px;
}

.sidebar-email {
	font-size: 12px;
	padding-top: 8px;	
	line-height: 12px;
}

.sidebar-directions {
		font-size: 12px;
}

#filter-by {
	width: 100%;
	float: left;
	margin-bottom: 20px;
}
#filter-by-categories {
	width: 100%;
	float: left;
	font-size: 13px;
	text-transform: uppercase;
	margin-bottom: 20px;
	a {
		background-color: #0099CF;
		padding: 6px 13px;
		-moz-border-radius: 6px;
		border-radius: 6px;
		color: #FFFFFF;
		text-decoration: none;
		@include transition(.3s ease all);

		&:hover {
			color: #FFFFFF;
			text-decoration: none;
			background-color: #3551A3;
		}
	}
	ul {
		padding: 0px;
		float: left;
		margin: 0px;
	}
	li {
		float: left;
		list-style: none;
		margin: 0px 8px 18px 0px;
	}
}
.faqrow {
	width: auto;
	overflow: hidden;
}
.question {
	width: auto;
	float: left;
	font-size: 13px;
	text-transform: uppercase;
	margin-bottom: 8px;
	margin-right: 8px;
	cursor: pointer;
	line-height: 1.3;
	position: relative;
	background-color: #0099CF;
	padding: 6px 13px;
	-moz-border-radius: 6px;
	border-radius: 6px;
	color: #ffffff;
	list-style-type: none;
}
.question a:link {
	color: #ffffff;
	text-decoration: none;
}
.question a:visited {
	color: #ffffff;
	text-decoration: none;
}
.question a:hover {
	color: #ffffff;
	text-decoration: none;
}
.question-image {
	width: 15px;
	height: 15px;
	position: absolute;
	left: 0px;
	top: 3px;
	background-image: url(images/faq.png);
	background-repeat: no-repeat;
	background-position: 0 0;
}
.question-image.close {
	background-position: -15px 0;
}
.answer-box {
	width: 100%;
}
.answer {
	width: 100%;
	float: left;
	display: none;
}
.alignleft, .post img.alignleft {
	display: inline;
	float: left;
	margin-right: 20px;
	margin-top: 4px;
}
.alignright, .post img.alignright {
	display: inline;
	float: right;
	clear: right;
	margin-left: 20px;
	margin-top: 4px;
}
.aligncenter, .post img.aligncenter {
	text-align: center;
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.post img.alignleft, .post img.alignright, .post img.aligncenter {
	margin-bottom: 20px;
}
.post ul {
	margin-bottom: 10px;
}
ol.commentlist {
	list-style: none;
	margin: 0;
	padding: 0;
}
ol.commentlist li {
	border: 1px solid #d5d5d5;
	margin: 0 0 10px;
	padding: 5px 7px 5px 64px;
	position: relative;
	list-style-type: none;
}
ol.commentlist li.pingback comment-author {
	padding: 0 170px 0 0;
	background-color: #F6FFED;
}
ol.commentlist li div.vcard {
	font-size: .8em;
	line-height: 1.5em;
}
ol.commentlist li div.vcard cite.fn {
	font-weight: bold;
	font-size: 1.3em;
}
ol.commentlist li div.vcard cite.fn a.url {
	color: #cc0000;
	text-decoration: none;
}
ol.commentlist li div.vcard cite.fn a.url:hover {
	color: #000;
}
ol.commentlist li div.vcard img.avatar {
	background: #fff;
	border: 1px solid #aaa;
	padding: 5px;
	left: 7px;
	position: absolute;
	top: 7px;
	margin-left: -60px;
}
ol.commentlist li div.comment-meta {
	position: absolute;
	right: 5px;
	text-align: right;
	top: 0px;
	font-size: .7em;
	color: #333333;
}
ol.commentlist li div.comment-meta a {
	color: #666666;
	text-decoration: none;
}
ol.commentlist li p {
	margin-top: 20px;
	margin-right: 0;
	margin-bottom: 12px;
	margin-left: 0;
}
ol.commentlist li ul {
	list-style: square;
	margin: 0 0 12px;
	padding: 0;
}
ol.commentlist li div.reply {
	border: 1px solid #999999;
	color: #333333;
	padding-left: 3px;
	padding-right: 3px;
	text-align: center;
	font-family: helvetica, arial, sans-serif;
	font-size: .8em;
	font-weight: bold;
	background-color: #CCCCCC;
	width: 50px;
}
ol.commentlist li div.reply:hover {
	background: #cc0000;
	border: 1px solid #cc0000;
	color: #FFFFFF;
}
ol.commentlist li div.reply a {
	color: #fff;
	text-decoration: none;
	text-transform: uppercase;
}
ol.commentlist li ul.children {
	list-style: none;
	margin: 12px 0 0;
	text-indent: 0;
}
ol.commentlist li ul.children li.depth-2 {
	margin: 0 0 3px;
}
ol.commentlist li ul.children li.depth-3 {
	margin: 0 0 3px;
}
ol.commentlist li ul.children li.depth-4 {
	margin: 0 0 3px;
}
ol.commentlist li ul.children li.depth-5 {
	margin: 0 0 3px;
}
ol.commentlist ul.children li.odd { /*background:#fff;*/
}
ol.commentlist ul.children li.even {/* background:#f6f6f6;*/
}
ol.commentlist li.pingback div.vcard {
	padding: 0 170px 0 0;
}
ol.pings li {
	border: 1px solid #d5d5d5;
	margin: 0 0 10px;
	padding: 5px 7px 5px 64px;
	position: relative;
	list-style-type: none;
	line-height: 1.3em;
}
ol.pings {
	width: auto;
	font-size: .7em;
}
ol.pings h3 {
	font-size: .9em;
}