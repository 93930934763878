.main-navigation {
	// clear: both;
	// display: block;
	// float: left;
	// width: 100%;
	// display: block;
	text-align: center;
	text-transform: uppercase;
	clear: both;
	width: 100%;
	
	margin-top: 10px;
	padding-top: 12px;
	

	@media screen and (min-width: $navbreakwidth) {
		width: auto;
		float: right;
	}

	@media screen and (min-width: $deskwidth) {
	
	}
	

	ul {
		display: none;
		list-style: none;
		margin: 0;
		padding-left: 0;
		list-style: none;
		margin: 0;
		padding-left: 0;

		ul {
			// box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
			// float: left;
			// position: absolute;
			// top: 1.5em;
			// left: -999em;
			// z-index: 99999;
			display: none;
			float: left;
			margin: 0;
			position: absolute;
			left: 0;
			width: 230px;
			z-index: 99999;
			padding-right: 0px;
			padding-top: 5px;

			ul {
				// left: -999em;
				// top: 0;
				left: 100%;
				top: 0;
			}

			li {
				&:hover > ul,
				&.focus > ul {
					left: 100%;
				}
			}

			a {
				width: 200px;
				font-size: 14px;
				text-align: left;
				font-weight: normal;
				height: auto;
				line-height: 1.4em;
				padding: 5px 14px;
				margin-left: 5px;
				margin-bottom: 0px;
				font-weight: bold;
			}

			:hover > a,
			.focus > a {
			}

			a:hover,
			a.focus {
			}
		}

		li:hover > ul,
		li.focus > ul {
			left: auto;
		}
	}
	li {
		float: left;
		position: relative;
		text-align: center;
		font-size: 14px;
		width: 100%;

		@media screen and (min-width: $navbreakwidth) {
			font-size: 12px;
			width: auto;
		}

		@media screen and (min-width: $midwidth) {
			font-size: 14px;
			width: auto;
		}

		@media screen and (min-width: $deskwidth) {
			font-size: 16px;
		}	

		&:hover > a,
		&.focus > a {
		}
		&:last-child a {
			width: auto;
			background-color: #3753A1;
			-moz-border-radius: 6px;
			border-radius: 6px;
			/*height: 18px;*/
			margin-top: 10px;
			line-height: 4px!important;
			padding: 20px 15px;
			display: block;
			@media screen and (min-width: $navbreakwidth) {
				padding: 20px 5px;
			}

			@media screen and (min-width: $deskwidth) {
				padding: 20px 15px;
			}	

			&:hover {
				background-color: #359DC7;
				color: #ffffff!important;
			}
		}
	}

	a {
		color: #ffffff;
		display: block;
		line-height: 26px;
		padding: 18px 13px 10px 13px;

		text-decoration: none;
		margin-bottom: 15px;
		@include transition(all .3s ease);

		@media screen and (min-width: $navbreakwidth) {
			padding: 18px 10px 10px 10px;
		}

		@media screen and (min-width: $deskwidth) {
			padding: 18px 20px 10px 20px;
		}	
	}

	.current_page_item > a,
	.current-menu-item > a,
	.current_page_ancestor > a,
	.current-menu-ancestor > a {
	}
	.sub-menu {
		background-image: url(images/bg-nav.png);
		background-repeat: repeat;
		padding-bottom: 10px;
		-moz-border-radius: 0 0 12px 12px;
		border-radius: 0 0 12px 12px;

		li {
			width: 100%;
			float: left;

		}
		a {
			color: #000000;
		}
	}
}

/* Small menu. */
.menu-toggle,
.main-navigation.toggled ul {
	display: block;
	background-color: rgba(255,255,255,.1);
	ul.sub-menu {
		background-color: rgba(255,255,255,.1);
	}
	
}


@media screen and (min-width: $navbreakwidth) {
	.menu-toggle {
		display: none;
	}
	.main-navigation ul {
		display: block;
	}
}
button.menu-toggle {
	border: 0px;
	text-shadow: none;
	background-color: rgba($color-blue,1);
	color: #fff;
	box-shadow: none;
	width: 100%;
	display: inline-block;
	margin: 10px auto;
	border-radius: 0;
	text-transform: uppercase;
	padding: 10px 0;
	font: {
		
		weight :300;
		size: 20px;
	}
	
	@media screen and (min-width: $navbreakwidth) {
		display: none;
	}
}

.comment-navigation,
.posts-navigation,
.post-navigation {

	.site-main & {
		margin: 0 0 1.5em;
		overflow: hidden;
	}

	.nav-previous {
		float: left;
		width: 50%;
	}

	.nav-next {
		float: right;
		text-align: right;
		width: 50%;
	}
}

// #access li:last-child a {
// 	width: 175px;
// 	background-color: #3753A1;
// 	-moz-border-radius: 6px;
// 	border-radius: 6px;
// 	padding-left: 0px!important;
// 	/*height: 18px;*/
// 	margin-top: 10px;
// 	line-height: 4px!important;
// 	padding: 20px 10px;
// 	display: block;
// }
// #access li:last-child a:hover {
// 	background-color: #359DC7;
// 	color: #ffffff!important;
// }
#access {
	// display: block;
	// margin-left: auto;
	// margin-right: auto;
	// width: 980px;
	// font-size: 15px;
	// height: auto;
	// text-transform: uppercase;
}
#access ul {
	// list-style: none;
	// margin: 0;
	// padding-left: 0;
}
#access li {
	// float: left;
	// position: relative;
	// text-align: center;
	// padding-right: 14px;
	// padding-left: 15px;
}
#access a {
	// color: #ffffff;
	// display: block;
	// line-height: 26px;
	// padding: 18px 0px 3px 10px;
	// text-decoration: none;
	// margin-bottom: 15px;
	// @include transition(all .3s ease);
}
#access ul ul {
	// display: none;
	// float: left;
	// margin: 0;
	// position: absolute;
	// left: 0;
	// width: 230px;
	// z-index: 99999;
	// padding-right: 0px;
	// padding-top: 5px;
}
// #access .sub-menu {
// 	background-image: url(images/bg-nav.png);
// 	background-repeat: repeat;
// 	padding-bottom: 10px;
// 	-moz-border-radius: 0 0 12px 12px;
// 	border-radius: 0 0 12px 12px;
// }
// #access .sub-menu li {
// 	width: 100%;
// 	float: left;
// }
// #access .sub-menu a {
// 	color: #000000;
// }
#access .sub-menu a:visited {
	color: #000000;
}
#access .sub-menu a:hover {
	color: red;
}
// #access ul ul ul {
// 	left: 100%;
// 	top: 0;
// }
// #access ul ul a {
// 	color: #444;
// 	font-size: 14px;
// 	text-align: left;
// 	font-weight: normal;
// 	height: auto;
// 	line-height: 1.4em;
// 	padding: 5px 14px;
// 	margin-left: 5px;
// 	margin-bottom: 0px;
// 	font-weight: bold;
// }
#access ul ul li {
	padding-right: 0px!important;
	padding-left: 0px!important;
	padding-top: 0px;
	background-image: none;
}
#access li:hover > a, #access ul ul :hover > a, #access a:focus {
	color: #000000;
}
#access li:hover > a, #access a:focus {
	color: #359DC7;
}
.current-menu-item a {
	padding-bottom: 3px;
	color: #359DC7;
}
#access .current-menu-item a {
	padding-bottom: 0px!important;
}
#access li:hover {
	color: red;
}
#access ul li:hover > ul {
	display: block;
}
#access .current-menu-item > a, #access .current-menu-ancestor > a, #access .current_page_item > a, #access .current_page_ancestor > a {
	color: #00A5D8;
}
#access li:last-child {
	border-right: 0px;
	background-image: none;
}
#mobile-navigation {
	visibility: hidden;
}
#mobile-hide {
	visibility: hidden;
}
#navigation {

	@media screen and (min-width: $tabwidth) {

	}

	@media screen and (min-width: $deskwidth) {
	
	}
	width: 100%;
	height: 52px;
	padding-top: 12px;
	float: left;
}
#navigation-bar {
	height: 0px;
	width: 0%;
	visibility: hidden;
}
#header-text {
	width: 550px;
	height: 95px;
	float: right;
	font-size: 22px;
	color: #262425;
	text-align: right;
	font-weight: bold;
	margin-top: 30px;
}
#framed {
	padding-bottom: 10px;
	float: left;
}
