/* FONT PATH
 * -------------------------- */

// @font-face {
//   font-family: 'FontAwesome';
//   src: url(if($fa-sass-asset-helper, fa-font-path('#{$fa-font-path}#{$fa-font-name}.eot?v=#{$fa-version}'), '#{$fa-font-path}#{$fa-font-name}.eot?v=#{$fa-version}'));
//   src: url(if($fa-sass-asset-helper, fa-font-path('#{$fa-font-path}#{$fa-font-name}.eot?v=#{$fa-version}#iefix'), '#{$fa-font-path}#{$fa-font-name}.eot?v=#{$fa-version}#iefix')) format('embedded-opentype'),
//     url(if($fa-sass-asset-helper, fa-font-path('#{$fa-font-path}#{$fa-font-name}.woff2?v=#{$fa-version}'), '#{$fa-font-path}#{$fa-font-name}.woff2?v=#{$fa-version}')) format('woff2'),
//     url(if($fa-sass-asset-helper, fa-font-path('#{$fa-font-path}#{$fa-font-name}.woff?v=#{$fa-version}'), '#{$fa-font-path}#{$fa-font-name}.woff?v=#{$fa-version}')) format('woff'),
//     url(if($fa-sass-asset-helper, fa-font-path('#{$fa-font-path}#{$fa-font-name}.ttf?v=#{$fa-version}'), '#{$fa-font-path}#{$fa-font-name}.ttf?v=#{$fa-version}')) format('truetype'),
//     url(if($fa-sass-asset-helper, fa-font-path('#{$fa-font-path}#{$fa-font-name}.svg?v=#{$fa-version}#fontawesomeregular'), '#{$fa-font-path}#{$fa-font-name}.svg?v=#{$fa-version}#fontawesomeregular')) format('svg');
//   font-weight: normal;
//   font-style: normal;
// }
