.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
	border: none;
	margin-bottom: 0;
	margin-top: 0;
	padding: 0;
}

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
	max-width: 100%;
}

/* Responive Iframes */
.embed-container { 
	position: relative; 
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
	height: auto;
} 
 
.embed-container iframe,
.embed-container object,
.embed-container embed { 
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/*--------------------------------------------------------------
## Captions
--------------------------------------------------------------*/
@import "captions";

/*--------------------------------------------------------------
## Galleries
--------------------------------------------------------------*/
@import "galleries";