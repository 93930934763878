h1, h2, h3, h4, h5, h6 {
	clear: both;
}

// .entry-content h1 {
// 	font-size: 30px;
// }

h1, h2, h3, h4, h5, h6 {
	margin-bottom: 10px;
	margin-top: 0px;
	font-weight: normal;
}
h1 {
	font-size: 24px;
	font-weight: bold;
	color: #000000;
	border-top: 4px solid #159ACD;
	border-bottom: 4px solid #159ACD;
	display: inline-block;
	padding-bottom: 10px;
	padding-top: 8px;
	margin-bottom: 20px;
	width: auto;
	@media screen and (min-width: $tabwidth) {
		font-size: 24px;
	}
	@media screen and (min-width: $midwidth) {
		font-size: 30px;
	}

	@media screen and (min-width: $deskwidth) {
		font-size: 38px;
	}
}
h2 {
	font-size: 20px;
	font-weight: bold;
	color: #0099CF;
}
h3 {
	font-size: 16px;
	color: #0099CF;
}
h4 {
	font-size: 14px;
}
h5 {
	font-size: 14px;
}
h6 {
	font-size: 14px;
}